import { Component, Input } from '@angular/core';
import { CustomControl } from 'src/app/_core/models/CustomForms.model';

@Component({
  selector: 'app-validation-message',
  template: `
    <ng-container *ngIf="control.touched || (control.auxControl && control.auxControl.touched && control.invalid)">
      <ng-container *ngFor="let validation of control.validations">
        <p *ngIf="control.hasError(validation.error)" class="small text-danger m-0" [innerHtml]="validation.message | translate"></p>
      </ng-container>
    </ng-container>
  `,
})
export class ValidationMessageComponent {
  @Input() vm = '';
  @Input() control: CustomControl;
  @Input() controlName = '';
  @Input() alert = false;
}
